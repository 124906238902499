<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="LBLBASEINFO">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--대상년도-->
              <c-datepicker
                :editable="editable"
                :disabled="true"
                type="month"
                label="LBL0010014"
                name="yearmonth"
                v-model="month.yearmonth"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-field
                :editable="editable"
                :disabled="true"
                :data="month"
                deptValue="manageSupervisorDeptCd"
                type="dept_user"
                label="관리감독자"
                name="manageSupervisorId"
                v-model="month.manageSupervisorId"
              />
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <c-table
          ref="table"
          :title="`${$comm.moment(month.yearmonth).format('YYYY년 MM월')} ${tab.label} 부서 실적 현황`"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="tab.results"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled&&tab.completeFlag!=='Y'"
          :usePaging="false"
          :isExcelDown="true"
          :filtering="false"
          :isFullScreen="true"
          :columnSetting="false"
        >
        </c-table>
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safetyHealthyTargetMonthAppr',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiSafetyHealthyTargetMonthId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      month: {
        saiSafetyHealthyTargetMonthId: '',  // 안전보건목표 일련번호
        saiSafetyHealthyTargetId: '',  // 안전보건목표 일련번호
        yearmonth: '',  // 년월
        saiSafetyHealthyTargetMonthStepCd: '',  // 진행상태
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        depts: [],
      },
      tab: '',
      grid: {
        merge: [{ index: 0, colName: "targetName" }],
        columns: [],
        data: [],
      },
      editable: false,
      detailUrl: '',
      holidayUrl: '',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let height = (this.contentHeight - 130);
      if (height < 650) {
        height = 650;
      }
      return String(height) + 'px';
    },
    gridHeight() {
      let height = (this.contentHeight - 180);
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    disabled() {
      return this.month.saiSafetyHealthyTargetMonthStepCd === 'SSHTM00002'
        // 결재중인 경우 비활성화
        || this.month.approvalStatusCd === 'ASC0000001'
    },
    tabItems() {
      let items = [];
      if (this.month.depts && this.month.depts.length > 0) {
        this.$_.forEach(this.month.depts, dept => {
          items.push({
            name: dept.deptCd, 
            label: dept.deptName,
            class: 'tab-bluegrey', icon: 'dangerous', 
            deptCd: dept.deptCd, 
            results: dept.results, 
            completeFlag: dept.completeFlag,
            badge: dept.completeFlag === 'Y' ? '완료' : null
          })
        })
      }
      
      return items;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.safetyHealthy.month.get.url;
      this.holidayUrl = selectConfig.sys.holiday.list.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiSafetyHealthyTargetMonthId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          saiSafetyHealthyTargetMonthId: this.popupParam.saiSafetyHealthyTargetMonthId,
        };
        this.$http.request((_result) => {
          this.$_.extend(this.month, _result.data);
          this.tab = this.month.depts[0].deptCd

          this.getHolidaies();
        },);
      } else {
        // 신규등록 * 해당 경우로 들어오지 않음
      }
    },
    getHolidaies() {
      this.$http.url = this.holidayUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.$comm.moment(this.month.yearmonth).format('YYYY')
      };
      this.$http.request((_result) => {
        this.holidaies = _result.data
        this.setGridHeader()
      },);
    },
    setGridHeader() {
      let yearmonth = this.month.yearmonth;
      let yearmonthDays = this.$comm.moment(yearmonth).daysInMonth();
      let childCols = [];
      for (let idx = 1; idx <= yearmonthDays; idx++) {
        let isHoliday = this.isHoliday(`${yearmonth}-${this.$_.padStart(idx, 2, '0')}`);
        childCols.push({
          name: `day${idx}`,
          field: `day${idx}`,
          label: `${idx}`,
          align: 'center',
          style: 'width:30px',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          color: 'blue', 
          month: 1,
          disableTarget: 'ehsFlag',
          disableCon: 'N',
          headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
          tdColor: isHoliday ? '#D9D9D9' : null
        })
      }
      let performanceCols = {
        name: 'performance',
        field: 'performance',
        label: this.$comm.moment(this.month.yearmonth).format('YYYY년 MM월'),
        child: childCols
      };
      this.grid.columns = [
        {
          name: 'targetName',
          field: 'targetName',
          label: '구분',
          align: 'center',
          style: 'width: 100px',
          sortable: false,
          fix: true,
        },
        {
          name: 'detailName',
          field: 'detailName',
          label: '점검내용',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          fix: true,
        },
        {
          name: 'inspectionCycle',
          field: 'inspectionCycle',
          label: '점검주기',
          align: 'center',
          style: 'width:80px',
          sortable: false,
          fix: true,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: '담당자',
          align: 'left',
          style: 'width:150px',
          sortable: false,
          fix: true,
        },
        {
          name: 'result',
          field: 'result',
          label: '점검결과',
          align: 'center',
          child: [
            {
              name: 'resultTeamLeader',
              field: 'resultTeamLeader',
              label: '해당팀장',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultSupportTeam',
              field: 'resultSupportTeam',
              label: '생산지원팀',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultManager',
              field: 'resultManager',
              label: '관리감독자',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
          ]
        },
        {
          name: 'relatedEvidence',
          field: 'relatedEvidence',
          label: '관련근거',
          align: 'left',
          style: 'width:270px',
          type: 'html',
          sortable: false
        },
      ];
      this.grid.columns.splice(4, 0, performanceCols)
    },
    isHoliday(day) {
      // 일 : 0
      // 토 : 6
      let dayOfWeek = this.$comm.moment(day).day();
      if ([0, 6].indexOf(dayOfWeek) > -1) {
        return true;
      } else if (this.$_.findIndex(this.holidaies, { holiday: day }) > -1) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>